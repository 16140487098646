const i18n = {
  'en-US': {
    'menu.exception': 'Exception page',
    'menu.exception.403': '403',
    'exception.result.403.description': 'Access to this resource on the server is denied.',
    'exception.result.403.back': 'Back'
  },
  'zh-CN': {
    'menu.exception': 'Exception page',
    'menu.exception.403': '403',
    'exception.result.403.description': 'Access to this resource on the server is denied.',
    'exception.result.403.back': 'Back'
  }
};
export default i18n;