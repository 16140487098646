import axios from 'axios';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
const API_URL = 'https://api.spiapi.top';
// const API_URL = 'https://testadmin.umki.cn'; 
// 后端API的地址 https://testadmin.umki.cn https://api.spiapi.top
const TOKEN_KEY = 'Authorization'; // 存储JWT令牌的本地存储键

export async function LoginApi(data = null) {
  const url = 'auth/login';

  // const decoded = jwt_decode('12');
  data.key = Base64.stringify(Utf8.parse(data.userName + '@.*./' + data.password + '@.*./' + new Date().getTime()));
  data.userName = 'UserName is Fail!';
  data.password = 'Password is hidden';
  const method = 'POST';
  const headers = {
    'Content-Type': 'application/json'
  };
  try {
    const res = await axios({
      method,
      url: `${API_URL}/${url}`,
      data,
      headers
    });
    return res.data;
  } catch (err) {
    return err;
  }
}
export async function Api(url, method, data = null) {
  const headers = {
    'Content-Type': 'application/json',
    authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY)
  };
  try {
    const res = await axios({
      method,
      url: `${API_URL}/${url}`,
      data,
      headers
    });
    if (res.code === 1005) {
      localStorage.setItem('userStatus', 'logout');
      window.location.href = '/login';
    } else {
      return res.data;
    }
  } catch (err) {
    return err;
  }
}